import { Link } from "gatsby";
import React from "react";
import { Facebook, Linkedin, Mail, Phone } from "react-feather";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import styled from "styled-components";

import logo from "../../assets/brand/logo.svg";
import gap from "../../assets/icons/gap.png";
import ifs from "../../assets/icons/ifs.png";
import qv from "../../assets/icons/qv.png";

const CoverLogos = styled.div`
	height: 32px;

	& > img {
		height: 32px;
		margin-right: 16px;
		display: inline-block;
	}

	@media (max-width: 420px) {
		display: flex;
		justify-content: space-evenly;
		height: 48px;

		& > img {
			height: 48px;
		}
	}
`;

const FooterContainer = styled.footer`
	max-width: 1080px;
	margin: 0 auto;
	margin-top: 128px;
	display: grid;
	grid-template-columns: max-content 1fr minmax(320px, max-content);
	grid-template-areas:
		"logo . contacts"
		"blurb . contacts"
		"spacer . contacts"
		"links . contacts";
	padding-bottom: 32px;
	font-family: "Poppins", serif;

	@media (max-width: 420px) {
		margin-top: 80px;
		padding: 0 16px 32px 16px;
		grid-template-columns: max-content 1fr;
		align-items: center;
		grid-column-gap: 24px;
		grid-row-gap: 24px;
		grid-template-areas:
			"logo blurb"
			"contacts contacts"
			"links links";
	}
`;

const FooterLogo = styled.img`
	width: 96px;
	height: 96px;
	object-fit: contain;
	grid-area: logo;
`;

const FooterBlurb = styled.div`
	grid-area: blurb;

	p {
		margin: 4px 0;
		font-weight: 500;
	}
`;

const FooterContacts = styled.div`
	display: grid;
	grid-area: contacts;
	grid-row-gap: 16px;
`;

const FooterContact = styled.a`
	display: grid;
	align-items: center;
	grid-template-columns: 40px 1fr;
	grid-column-gap: 24px;
	background-color: rgba(22, 29, 70, 0.1);
	border-radius: 24px;
	padding: 12px 24px;
	transition: 200ms;
	color: inherit;
	text-decoration: none;

	&:hover {
		background-color: rgba(22, 29, 70, 0.2);
	}

	svg {
		height: 32px;
		width: 32px;
	}

	p,
	em {
		margin: 0;
		color: ${({ theme }) => theme.colors.text};
		line-height: 1.5;
		font-weight: 700;
		font-style: normal;
	}

	em {
		opacity: 0.6;
		text-transform: uppercase;
		font-size: 14px;
	}

	p {
		color: ${({ theme }) => theme.colors.text};
		font-size: 20px;
		letter-spacing: 0px;
		line-height: 1.5;
		font-weight: 600;
		font-style: normal;
	}
`;

const FooterSocials = styled(FooterContact)`
	display: flex;
	padding-left: 12px;
	grid-column-gap: 0;
`;

const FooterLinks = styled.div`
	grid-area: links;
	margin-bottom: -12px;
	margin-left: -12px;
	display: grid;
	grid-auto-columns: max-content;
	grid-auto-flow: column;
	grid-column-gap: 16px;
	align-items: center;

	@media (max-width: 420px) {
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		padding: 0 8px;
		margin: 0;
		grid-row-gap: 16px;
	}
`;

const FooterLink = styled(Link)`
	display: inline-block;
	font-family: "Poppins", serif;
	color: ${({ theme }) => theme.colors.text};
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
	padding: 12px;
	border-radius: 12px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
`;

const FooterSocial = styled.a`
	display: inline-block;
	color: inherit;
	padding: 12px;
	border-radius: 16px;
	height: 32px;
	width: 32px;
	margin-right: 8px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	svg {
		height: 32px;
		width: 32px;
	}
`;

const Footer = () => {
	const { t } = useTranslation();

	return (
		<Element name="contacts">
			<FooterContainer>
				<FooterLogo src={logo} alt="" />
				<FooterBlurb>
					<p>{t("where.address.name")}</p>
					<p>P.IVA 01343340293</p>
					<p>© {new Date().getFullYear()}</p>
				</FooterBlurb>
				<FooterLinks>
					<FooterLink to="">{t("contacts.privacyPolicy")}</FooterLink>
					<FooterLink to="">{t("contacts.cookiePolicy")}</FooterLink>
					<CoverLogos>
						<img src={ifs} alt="IFS food" />
						<img src={qv} alt="QV Veneto" />
						<img src={gap} alt="Global Gap" />
					</CoverLogos>
				</FooterLinks>
				<FooterContacts>
					<FooterContact href="tel:0426664162">
						<Phone />
						<div>
							<em>{t("contacts.phoneLabel")}</em>
							<p>042 666 4162</p>
							<p>346 144 9274</p>
						</div>
					</FooterContact>
					<FooterContact href="mailto:info@uniagro.it">
						<Mail />
						<div>
							<em>{t("contacts.emailLabel")}</em>
							<p>info@uniagro.it</p>
						</div>
					</FooterContact>
					<FooterSocials>
						<FooterSocial href="https://www.facebook.com/uniagro-srl-221886354542137/">
							<Facebook />
						</FooterSocial>
						<FooterSocial href="">
							<Linkedin />
						</FooterSocial>
					</FooterSocials>
				</FooterContacts>
			</FooterContainer>
		</Element>
	);
};

export default Footer;
