import React from "react";
import { Sticky, StickyContainer } from "react-sticky";
import styled, { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";

import appTheme from "../../helpers/theme";
import Footer from "../Footer";
import Navbar from "../Navbar";

const HiddenOnMobile = styled.div`
	@media (max-width: 420px) {
		display: none;
	}
`;

const HiddenOnLaptop = styled.div`
	@media (min-width: 421px) {
		display: none;
	}
`;

interface LayoutProps {
	children: React.ReactNode | React.ReactNode[];
}

const LandingLayout: React.FC<LayoutProps> = ({
	children,
	...props
}: LayoutProps) => {
	// eslint-disable-next-line react/jsx-props-no-spreading
	return (
		<div style={{ overflowX: "hidden" }}>
			<ThemeProvider theme={appTheme} {...props}>
				<Normalize />
				<StickyContainer>
					<HiddenOnLaptop>
						<Navbar compact />
					</HiddenOnLaptop>
					<HiddenOnMobile>
						<Sticky topOffset={256}>
							{({ style, isSticky }) => (
								<div style={{ ...style, zIndex: 10 }}>
									<Navbar style={style} sticky={isSticky} />
								</div>
							)}
						</Sticky>
					</HiddenOnMobile>
					{children}
					<Footer />
				</StickyContainer>
			</ThemeProvider>
		</div>
	);
};

export default LandingLayout;
