import { darken } from "polished";
import React, { PropsWithoutRef } from "react";
import styled, { css } from "styled-components";

type ButtonColor = "primary" | "secondary" | "black" | "white";

interface ButtonBodyProps {
	color: ButtonColor;
}

const ButtonBody = styled.button<ButtonBodyProps>`
	${({ color, theme }) =>
		color === "black" || color === "white"
			? css`
					background-color: ${theme.colors[color]};
					color: ${color === "white" ? theme.colors.black : theme.colors.white};

					&:hover {
						background-color: ${darken(0.1, theme.colors[color])};
					}
			  `
			: css`
					background-color: ${theme.colors[color]?.main};
					color: ${theme.colors[color]?.contrastColor};

					&:hover {
						background-color: ${darken(0.1, theme.colors[color].main)};
					}
			  `}
	border: none;
	height: 56px;
	padding: 0 48px;
	overflow: visible;
	border-radius: 16px;
	font-family: "Poppins", serif;
	font-size: 16px;
	font-weight: 700;
	outline: none;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;

	@media (max-width: 420px) {
		height: 48px;
		padding: 0 32px;
	}
`;

export interface ButtonProps
	extends PropsWithoutRef<JSX.IntrinsicElements["button"]> {
	children: React.ReactNode | React.ReactNode[];
	color?: ButtonColor;
}

const Button: React.FC<ButtonProps> = ({
	color,
	children,
	...rest
}: ButtonProps) => {
	return (
		<ButtonBody color={color} {...rest}>
			{children}
		</ButtonBody>
	);
};

Button.defaultProps = {
	color: "primary",
};

export default Button;
