import { motion } from "framer-motion";
import React, { useState } from "react";
import { Link } from "react-scroll";
import styled, { css, keyframes } from "styled-components";

import logo from "../../assets/brand/logo.svg";
import Button from "../Button";

const NavbarContent = styled.div`
	position: relative;
	max-width: 1080px;
	height: 100%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: min-content min-content;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;

	@media (max-width: 420px) {
		box-sizing: border-box;
		grid-template-columns: 1fr;
		justify-content: start;
		height: 100%;

		background-color: ${({ theme }) => theme.colors.white};
		z-index: 10000;
		/* box-shadow: 0 8px 32px -8px rgba(0, 0, 0, 0.25); */
	}
`;

interface NavbarContainerProps {
	$sticky?: boolean;
	open: boolean;
}

const popin = keyframes`
	from {
		transform: translateY(-128px);
	}

	to {
		transform: translateY(0px);
	}
`;

const NavbarContainer = styled.div<NavbarContainerProps>`
	height: 80px;

	@media (max-width: 420px) {
		position: fixed;
		height: ${({ open }) => (open ? "auto" : "64px")};
		overflow: hidden;
		padding: 12px;
	}

	${({ $sticky }) =>
		$sticky &&
		css`
			padding: 12px 0;

			${NavbarContent} {
				background-color: ${({ theme }) => theme.colors.white};
				z-index: 10000;
				padding: 16px;
				box-shadow: 0 8px 32px -8px rgba(0, 0, 0, 0.25);
				animation: 0.3s linear forwards ${popin};
			}
		`};
`;

const NavbarLogo = styled.div`
	@media (max-width: 420px) {
		padding: 8px 16px;
		z-index: 1;
	}

	img {
		height: 80px;

		@media (max-width: 420px) {
			height: 40px;
		}
	}
`;

const NavbarLinks = styled.div`
	display: grid;
	grid-gap: 8px;
	grid-auto-columns: max-content;
	grid-auto-flow: column;
	align-items: center;

	@media (max-width: 420px) {
		top: 80px;
		grid-auto-flow: row;
		z-index: 10;
		background-color: ${({ theme }) => theme.colors.white};
		width: 100%;
		padding: 16px;
		box-sizing: border-box;
		border-bottom-left-radius: 24px;
		border-bottom-right-radius: 24px;
		/* box-shadow: 0 8px 32px -8px rgba(0, 0, 0, 0.25); */
		grid-auto-columns: 1fr;
		text-align: center;
	}
`;

const NavbarLink = styled(Link)`
	display: inline-block;
	font-family: "Poppins", serif;
	color: ${({ theme }) => theme.colors.text};
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;
	padding: 12px;
	border-radius: 12px;
	cursor: pointer;

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
`;

interface NavbarProps {
	sticky: boolean;
	compact?: boolean;
	style: any;
}

const Navbar: React.FC<NavbarProps> = ({
	sticky,
	compact,
	style,
}: NavbarProps) => {
	const [open, setOpen] = useState(false);

	if (compact)
		return (
			<NavbarLogo onClick={() => setOpen(!open)}>
				<img src={logo} alt="logo" />
			</NavbarLogo>
		);

	return (
		<NavbarContainer $sticky={sticky} style={style} open={open}>
			<NavbarContent>
				<NavbarLogo onClick={() => setOpen(!open)}>
					<img src={logo} alt="logo" />
				</NavbarLogo>
				<NavbarLinks>
					<NavbarLink to="about" duration={500} offset={-224} smooth>
						Chi siamo
					</NavbarLink>
					<NavbarLink to="products" duration={500} offset={-224} smooth>
						Prodotti
					</NavbarLink>
					<NavbarLink to="where" duration={500} smooth>
						Dove siamo
					</NavbarLink>
					<Button>
						<Link to="contacts" duration={500} smooth>
							Contattaci
						</Link>
					</Button>
				</NavbarLinks>
			</NavbarContent>
		</NavbarContainer>
	);
};

export default Navbar;
