const React = require("react");
const LandingLayout = require("./src/components/LandingLayout");

require("typeface-poppins");
require("typeface-rozha-one");

// Wraps every page in a component
// exports.wrapPageElement = ({ element, props }) => {
// 	return <LandingLayout {...props}>{element}</LandingLayout>;
// };
