const appTheme = {
	colors: {
		primary: { main: "#008348", contrastColor: "#ffffff" },
		secondary: { main: "#FFAA22", contrastColor: "#ffffff" },
		text: "#161D46",
		black: "#121212",
		white: "#ffffff",
	},
};

export default appTheme;
